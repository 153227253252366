<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { link } from 'svelte-navigator';

  import type { ICategory } from '../../../interfaces/IExperience';

  export let categories: ICategory[];

  const dispatch = createEventDispatcher();
</script>

<table class="w-full text-sm text-left text-gray-500">
  <thead class="text-xs text-gray-700 uppercase bg-gray-50">
    <tr>
      <th scope="col" class="px-6 py-3">Id</th>
      <th scope="col" class="px-6 py-3">English name</th>
      <th scope="col" class="px-6 py-3">Spanish name</th>
      <th scope="col" class="px-6 py-3">Action</th>
    </tr>
  </thead>
  <tbody>
    {#each categories as category}
      <tr class="bg-white border-b">
        <th scope="row" class="px-6 py-4 font-medium text-gray-900"
          >{category.id}</th
        >
        <td class="px-5 py-3">{category.englishName}</td>
        <td class="px-5 py-3">{category.spanishName}</td>
        <td class="px-5 py-3">
          <a
            href={`/admin/edit/category/${category.id}`}
            use:link
            class="font-medium text-primary hover:underline">Edit</a
          >
          <button
            data-cy="delete-category-{category.id}"
            on:click={() =>
              dispatch('deleteCategory', {
                categoryId: category.id,
                categoryName: category.englishName,
              })}
            class="font-medium text-primary hover:underline">Delete</button
          >
        </td>
      </tr>
    {/each}
  </tbody>
</table>
