<script lang="ts">
  import UserIcon from '../../assets/svg/UserIcon.svelte';
  import DangerAlert from '../../lib/components/alerts/DangerAlert.svelte';
  import UserTables from '../../lib/components/tables/UserTables.svelte';
  import { getUsers } from '../../lib/services/together/together.api';

  const fetchUsers = async () => {
    return await getUsers();
  };
</script>

<div class="flex flex-col gap-4 px-4 md:px-10 mx-auto w-full min-h-screen p-4">
  <div
    class="flex items-center justify-center bg-white border border-gray-200 rounded-lg shadow-sm"
  >
    <h3 class="text-3xl p-2 font-bold">List of users</h3>
  </div>
  {#await fetchUsers()}
    <div
      class="flex flex-col lg:flex-row gap-4 items-center justify-center w-full animate-pulse"
    >
      <div
        class="h-24 w-full lg:w-3/6 bg-white border border-gray-100 rounded-lg shadow-sm p-2"
      >
        <div class="flex flex-row p-2 gap-4">
          <div class="flex items-center" />
          <div class="flex flex-col" />
        </div>
      </div>
      <div
        class="h-24 w-full lg:w-3/6 bg-white border border-gray-100 rounded-lg shadow-sm p-2"
      >
        <div class="flex flex-row justify-center items-center p-2" />
      </div>
    </div>
    <div
      class="relative overflow-x-auto h-48 bg-white border border-gray-100 shadow-sm sm:rounded-lg animate-pulse"
    />
  {:then response}
    <div
      class="flex flex-col lg:flex-row gap-4 items-center justify-center w-full"
    >
      <div
        class="h-24 w-full bg-white border border-gray-200 rounded-lg shadow-sm p-2"
      >
        <div class="flex flex-row p-2 gap-4">
          <div class="flex items-center">
            <div class="bg-gray-200 rounded-full p-3">
              <UserIcon iconSize="h-5 w-5" iconColor="text-gray-500" />
            </div>
          </div>
          <div class="flex flex-col">
            <h3 class="text-lg font-semibold text-gray-500">User count:</h3>
            <span class="text-lg text-center font-bold">{response.count}</span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="relative overflow-x-auto bg-white border border-gray-200 shadow-sm sm:rounded-lg"
    >
      <UserTables users={response.users} />
    </div>
  {:catch error}
    <DangerAlert alertMessage={error.message} alertDescription={''} />
  {/await}
</div>
