<script lang="ts">
  import { validator } from '@felte/validator-yup';
  import { createForm } from 'felte';
  import * as yup from 'yup';

  import Button from '../../lib/components/button/Button.svelte';
  import Input from '../../lib/components/input/Input.svelte';
  import { ToastNotification } from '../../lib/services/toast';
  import {
    createCategory,
    getCategory,
    updateCategory,
  } from '../../lib/services/together/together.api';

  export let id: number | undefined = undefined;

  let isEditing = false;

  const schema = yup.object({
    'english-name': yup.string().required('English name is required'),
    'spanish-name': yup.string().required('Spanish name is required'),
  });

  const initialValues = {
    'english-name': '',
    'spanish-name': '',
  };

  const fields = [
    {
      name: 'english-name',
      label: 'English Name',
      placeholder: 'English Name',
    },
    {
      name: 'spanish-name',
      label: 'Spanish Name',
      placeholder: 'Spanish Name',
    },
  ];

  const { form, errors, isSubmitting, reset, setFields, setInitialValues } =
    createForm({
      initialValues,
      extend: [validator({ schema })],
      onSubmit: async (values) => {
        try {
          const categoryData = {
            englishName: values['english-name'],
            spanishName: values['spanish-name'],
          };

          isEditing
            ? await updateCategory({ ...categoryData, id })
            : await createCategory(categoryData);

          ToastNotification.success(
            isEditing
              ? 'Category updated successfully'
              : 'Category created successfully',
          );
          isEditing && setInitialValues(values);
          reset();
        } catch (error) {
          const errorMessage = error?.response?.data?.message ?? error.message;
          ToastNotification.error(errorMessage);
        }
      },
    });

  const loadCategory = async () => {
    try {
      const category = await getCategory(id);
      setFields({
        'english-name': category.englishName,
        'spanish-name': category.spanishName,
      });
    } catch (error) {
      ToastNotification.error('Failed to load category data');
    }
  };

  $: {
    isEditing = id !== undefined;
    if (isEditing) loadCategory();
  }
</script>

<div class="flex flex-col gap-2 px-4 md:px-10 mx-auto w-full min-h-screen p-4">
  <div
    class="flex items-center justify-center bg-white border border-gray-200 rounded-lg shadow-sm p-2"
  >
    <h3 class="text-2xl font-bold">
      {isEditing ? 'Edit Category' : 'Add New Category'}
    </h3>
  </div>

  <form class="flex flex-col gap-4" use:form>
    {#each fields as { name, label, placeholder }}
      <Input
        styleClass="w-full"
        type="text"
        name={name}
        label={label}
        placeholder={placeholder}
        error={$errors[name]?.[0] ?? ''}
      />
    {/each}

    <Button
      isLoading={$isSubmitting}
      testId="submit-category-button"
      type="submit"
      text={isEditing ? 'Update Category' : 'Create Category'}
    />
  </form>
</div>
