<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { link } from 'svelte-navigator';

  import { type IExperience, planNames } from '../../../interfaces/IExperience';

  export let experiences: IExperience[];

  const dispatch = createEventDispatcher();
</script>

<table class="w-full text-sm text-left text-gray-500">
  <thead class="text-xs text-gray-700 uppercase bg-gray-50">
    <tr>
      <th scope="col" class="px-6 py-3">Id</th>
      <th scope="col" class="px-6 py-3">Experience name</th>
      <th scope="col" class="px-6 py-3">Plan</th>
      <th scope="col" class="px-6 py-3">Action</th>
    </tr>
  </thead>
  <tbody>
    {#each experiences as experience}
      <tr class="bg-white border-b">
        <th scope="row" class="px-6 py-4 font-medium text-gray-900"
          >{experience.id}</th
        >
        <td class="px-5 py-3">{experience.name}</td>
        <td class="px-5 py-3">{planNames[experience.allowInPlan]}</td>
        <td class="px-5 py-3">
          <a
            href={`/admin/experience/${experience.id}`}
            use:link
            class="font-medium text-primary hover:underline">View</a
          >
          <a
            href={`/admin/edit/experience/${experience.id}`}
            use:link
            class="font-medium text-primary hover:underline">Edit</a
          >
          <button
            data-cy="delete-experience-{experience.id}"
            on:click={() =>
              dispatch('deleteExperience', {
                experienceId: experience.id,
                experienceName: experience.name,
              })}
            class="font-medium text-primary hover:underline">Delete</button
          >
        </td>
      </tr>
    {/each}
  </tbody>
</table>
